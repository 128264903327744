import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MessageService} from '../../message.service';

@Component({
    selector: 'app-edit-contact-dialog',
    templateUrl: './edit-contact-dialog.component.html',
    styleUrls: ['./edit-contact-dialog.component.css']
})
export class EditContactDialogComponent implements OnInit {

    constructor(
        public confirmUploadDialogRef: MatDialogRef<EditContactDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private http: HttpClient
    ) {

    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.confirmUploadDialogRef.close();
    }

    saveContact(name): void {
        const url = 'https://zw.viajesinteractiva.com/admin/api/contacts';

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Authorization': localStorage.getItem('token'),
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
        });

        this.http.post(url,
            {name: name, chat_id: this.data.contact.id},
            {headers: httpHeaders, observe: 'response'}
        ).subscribe();

        this.data.contact.name = name;

        this.confirmUploadDialogRef.close();
    }
}
