import { Component, OnInit, OnDestroy } from '@angular/core';
import * as $ from 'jquery';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styles: []
})
export class ItemComponent implements OnInit, OnDestroy {

  menu: any;
  userFilter: any = { title: '' };
  active: string;
  cargando: Subscription = new Subscription();

  constructor(private store:Store<AppState>, private router: Router) {
    this.active = this.router.url.split("/")[1];
  }

  ngOnInit() {
    this.cargando = this.store.select('auth')
      .pipe(filter(menu => menu.user != null))
      .pipe(filter(menu => typeof menu.user['role']['permissions']['controllers'] !== 'undefined'))
    .subscribe(menu => {
        this.menu = menu.user['role']['permissions']['controllers'];
    });
  }

  ngOnDestroy() {
    this.cargando.unsubscribe();
  }

  openClick(item:number) {

      $(".sidebar-submenu").slideUp(200);

      if (
        $('.item' + item)
          .parent(".sidebar-dropdown")
          .hasClass("active")
      ) {
        console.log('cerrar');
        $(".item" + item + "").parent(".sidebar-dropdown").removeClass("active");
        $('.item' + item)
          .parent(".sidebar-dropdown")
          .removeClass("active");
      } else {

        console.log('abrir');
        $(".sidebar-dropdown").removeClass("active");

        $(".item" + item + "").parent(".sidebar-dropdown").removeClass("active");
        $('.item' + item)
          .parent(".sidebar-dropdown")
          .children(".sidebar-submenu")
          .slideDown(200);
        $('.item' + item)
          .parent(".sidebar-dropdown")
          .addClass("active");
      }

    }

}
