import { Action } from "@ngrx/store";
import { Quotes } from "./quotes.model";

export const LIST_QUOTE = '[Quotes] Listado de cotizaciones';

export const FILTER_QUOTE = '[Quotes] Listado de cotizaciones filtradas por asesor';
export const GET_QUOTE = '[Quotes] Datos de cotizaciones';
export const EMPTY_QUOTE = '[Quotes] Empty cotizaciones';


export class ListQuotesAction implements Action {
  readonly type = LIST_QUOTE;
  constructor(public quote: Quotes) { }
}

export class FilterQuotesAction implements Action {
  readonly type = FILTER_QUOTE;
  constructor(public quote: Quotes) { }
}
export class GetQuotesAction implements Action {
      readonly type = GET_QUOTE;
      constructor(public quote: Quotes) { }
}
export class EmptyQuotesAction implements Action {
      readonly type = EMPTY_QUOTE;
}
export type acciones = ListQuotesAction | GetQuotesAction | EmptyQuotesAction | FilterQuotesAction;
