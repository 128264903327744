import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from "@angular/forms";
import { UsersService } from '../users.service';
import { Users } from '../users.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styles: []
})


export class UserDetailComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;
  hide = true;
  id: number;
  data: any;

  user: Subscription = new Subscription();
  dataUser: any;
  roles: any;
  estados: any;

  constructor(private usersService: UsersService,
    private router: ActivatedRoute, private store: Store<AppState>) { 
    this.formGroup = new FormGroup({
      name: new FormControl(''),
      last_name: new FormControl(''),
      email: new FormControl(''),
      password: new FormControl(''),
      status: new FormControl('active'),
      id: new FormControl(''),
      role_id: new FormControl(''),
    });
  }

  ngOnInit() {
    this.getDataUser();
  }

  ngOnDestroy() {
    this.user.unsubscribe();
  }


  onSubmit() {
    this.router.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number
      const datos = new Users({ ... this.formGroup.value });
      if (isNaN(this.id)) {
        this.usersService.setUser(datos, null);
      } else {
        this.usersService.setUser(datos, this.id);
      }
    });
  }

  getDataUser() {

    this.getStatus();

    this.router.params.subscribe(params => {

      this.id = +params['id']; // (+) converts string 'id' to a number
      if (isNaN(this.id)) {
        this.data = {
          title: 'Crear Usuario',
          subTitle: 'Desde esta sección se podrá crear los usuarios que ingresan a la plataforma.'
        };
      } else {
        this.data = {
          title: 'Editar Usuario',
          subTitle: 'Desde esta sección se podrá editar los datos del usuario.'
        };
        this.usersService.getUser(this.id);

        this.user = this.store.select('users')
        .pipe(filter(usuario => usuario.users != null))
        .pipe(filter(usuario => typeof usuario.users.id !== 'undefined'))
        .subscribe(
          usuario => {

              this.dataUser = {
                'id': usuario.users.id,
                'name': usuario.users.name,
                'last_name': usuario.users.last_name,
                'email': usuario.users.email,
                'status': usuario.users.status,
                'password': '',
                'role_id': usuario.users.role_id,
              };

              this.formGroup.setValue(this.dataUser);

           }
        );


      }
    });
  }


  getStatus() {
    this.store.select('settings')
      .pipe(
        filter(set => set.settings != null)
      )
      .subscribe(
        set => {
          this.roles = set.settings.roles;
          this.estados = set.settings.statuses.general;
        }
      );
  }


}
