import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Subscription } from 'rxjs';
import { ApiService } from "../../api.service";


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class SidebarComponent implements OnInit {

  menu: any;
  logo:string;
  

  constructor(
    private apiService: ApiService) {

    //this.menu = ['uno', 'dos'];
  }

  ngOnInit() {
    this.logo = this.apiService.getLogo();

  }


  
}
