export class Users {
      public name: string;
      public last_name: string;
      public email: string;
      public id: number;
      public password: string;
      public status: string;
      public role_id: number;

      constructor(obj: DataObj) {
            this.name = obj && obj.name || null;
            this.email = obj && obj.email || null;
            
            this.last_name = obj && obj.last_name || null;
            if (obj.password) {
                  this.password = obj.password;
            }
            if (obj.id) {
                  this.id = obj.id;
            }
            this.status = obj && obj.status || null;
            this.role_id = obj && obj.role_id || null;
      }
}

interface DataObj {
      id: number;
      email: string;
      name: string;
      last_name: string;
      password: string;
      status: string;
      role_id: number;
}