import * as fromActions from './quotes.actions';
import {Quotes} from './quotes.model';

export interface QuotesState {
  quotes: Quotes;
}

const estadoInicial: QuotesState = {
  quotes: null
}

export function quotesReducer(state = estadoInicial, action: fromActions.acciones): QuotesState {
  switch (action.type) {
    case fromActions.LIST_QUOTE:
      return {
        quotes: {...action.quote}
      };
    case fromActions.FILTER_QUOTE:
      return {
        quotes: {...action.quote}
      };
    case fromActions.GET_QUOTE:
      return {
        quotes: action.quote
      };
    case fromActions.EMPTY_QUOTE:
      return {
        quotes: null
      };
    default:
      return state;
  }
}
