import {Component, Input, OnInit} from '@angular/core';
import {ConfirmUploadDialogComponent} from '../attachment/confirm-upload-dialog/confirm-upload-dialog.component';
import {MatDialog} from '@angular/material';
import {Contact} from '../../contacts/contact.model';
import {EditContactDialogComponent} from './edit-contact-dialog/edit-contact-dialog.component';

@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrls: ['./edit-contact.component.css']
})
export class EditContactComponent implements OnInit {
  @Input() contact: Contact;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  openDialog(event: Event): void {
    const dialogRef = this.dialog.open(EditContactDialogComponent, {
      height: '300px',
      width: '500px',
      data: {
        event: event,
        contact: this.contact
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
