import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, FormArray, Validators} from '@angular/forms';
import {ApiService} from '../../api.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {WhtsState} from '../wht.reducers';
import {Whts} from '../whts.model';
import {MatTableDataSource, MatSort, MatPaginator, MatList} from '@angular/material';
import {ChatService} from './chat.service';
import {MatDialog} from '@angular/material/dialog';
import {NewChatDialogComponent} from './new-chat-dialog.component';
import {MessageService} from './messages/message.service';
import {ContactService} from './contacts/contact.service';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, AfterViewInit {

    subscription: Subscription = new Subscription();
    formGroup: FormGroup;
    whts: any;
    resultsLength: number;
    height: number;

    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild('chatElement', {static: true}) chatElement: ElementRef;

    constructor(
        private store: Store<WhtsState>,
        private router: ActivatedRoute,
        private api: ApiService,
        private formBuilder: FormBuilder,
        public chatService: ChatService
    ) {

        /*this.store.select('auth')
          .pipe(
            filter(set => set.user != null)
          )
          .subscribe(
            set => {
              this.user_id = set.user.id;
            }
          );*/

        const numericRegex = '^\\d*$';

        this.formGroup = new FormGroup({
            phone_number: new FormControl('', [Validators.required, Validators.minLength(7), Validators.pattern(numericRegex)]),
            msj: new FormControl(''),
            user_id: new FormControl(''),
            description: new FormControl('')
        });


    }

    ngOnInit() {
        localStorage.removeItem('is_my_chats_filter_active');
        localStorage.removeItem('filter_user_id');
        this.chatService.start();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.height = undefined;

            const height = this.chatElement.nativeElement.parentElement.parentElement.clientHeight;

            if (typeof height !== 'undefined') {
                this.height = height;
            }
        });
    }

}
