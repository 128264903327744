export class Contact {
  public id: string;
  public name: string;
  public image: string;
  public metadata: [];
  public message: {};
  public whts_track: [];
  public whts_tag: [];

  constructor(id: string, name: string, image: string, metadata: [], message: {}, whts_track: [], whts_tag: []) {
    this.id = id;
    this.name = name;
    this.image = image;
    this.metadata = metadata;
    this.message = message;
    this.whts_track = whts_track;
    this.whts_tag = whts_tag;
  }
}
