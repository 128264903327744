import { Action } from "@ngrx/store";
import { User } from "./user.model";

export const SET_USER = '[Auth] Set user';
export const LOGOUT_USER = '[Auth] Logout';

export class SetUserAction implements Action {
      readonly type = SET_USER;
      constructor( public user: User ) {}
}


export class LogOutUserAction implements Action {
      readonly type = LOGOUT_USER;
}

export type acciones = SetUserAction | LogOutUserAction;