import {Component, Input, OnInit} from '@angular/core';
import {ContactService} from "./contact.service";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {ContactsState} from "./contacts.reducers";
import {MessageService} from "../messages/message.service";
import {Contact} from "./contact.model";
import {ChatService} from "../chat.service";

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {

  @Input() contacts: Contact[];
  @Input() height: number;

  subscription: Subscription = new Subscription();

  constructor(
    private chatService: ChatService,
    private messageService: MessageService,
    private store: Store<ContactsState>
  ) {}

  ngOnInit() {
    /*this.subscription = this.store.select('contacts').subscribe((data: any) => {
      if (data.contact) {
        const array = Object.keys(data.contact).map(key => ({phone_number: key, unread_messages: data.contact[key].unread_messages}));

        this.contacts = array;
      }
    })*/
  }

}
