import { Action } from "@ngrx/store";
import { Search } from './search.model';

export const SEARCH = '[SEARCH Hotels] Busqueda de hoteles';


export class SearchAllsAction implements Action {
      readonly type = SEARCH;
      constructor(public search: Search) { }
}

export type acciones = SearchAllsAction;