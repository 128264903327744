import {Injectable, OnInit} from '@angular/core';
import {ContactService} from './contacts/contact.service';
import {MessageService} from './messages/message.service';
import {Contact} from './contacts/contact.model';
import {DesactivarLoadingAction} from '../../shared/ui.accions';
import {ListContactsAction} from './contacts/contacts.actions';
import {Store} from '@ngrx/store';
import {ContactsState} from './contacts/contacts.reducers';
import {map} from 'rxjs/operators';
import {Message} from './messages/message.model';
import * as Color from 'color';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  /*
  All the contacts that the number has talked to.
  */
  public contacts: Contact[] = [];

  /*
    The clicked contact
  */
  public selectedContact: Contact;

  public lastContact: Contact;

  public messages: Message[] = [];

  constructor(
    private contactService: ContactService,
    private messageService: MessageService,
    private store: Store<ContactsState>
  ) {
  }

  start(): void {
    this.contactService.getContacts();

    /*this.store.select('contacts').subscribe((data: any) => {
      if (data.contact) {
        this.contacts = Object.values(data.contact);
        /*Object.keys(data.contact).map(key => {
          this.contacts.push(new Contact(key, data.contact[key].));
        });*
      }
    });*/
  }

  getConversation(contact: Contact) {
    if (this.lastContact) {
      const currentColorLc = this.selectedContact.metadata['user']['color'];
      this.selectedContact.metadata['user']['color'] = Color(currentColorLc).lighten(0.2);
    }

    this.selectedContact = contact;

    let currentColor = this.selectedContact.metadata['user'] ? this.selectedContact.metadata['user']['color'] : '#ffffff';

    if (this.selectedContact.metadata['user'] == null) {
      this.selectedContact.metadata['user'] = {};
    }

    if (!currentColor) {
      currentColor = '#ffffff';
    }

    this.selectedContact.metadata['user'].color = Color(currentColor).darken(0.2);

    this.lastContact = this.selectedContact;

    this.messageService.getMessages(contact);

    /*this.store.select('messages').subscribe((data: any) => {

      if (data.message) {
        this.messages = Object.values(data.message);
      }
    });*/

    this.contactService.getContacts();
  }
}

