import * as fromActions from './messages.actions';
import {Message} from "./message.model";

export interface MessagesState {
  message: Message
}

const initialStatus: MessagesState = {
  message: null
};

export function messagesReducer(state = initialStatus, action: fromActions.actions) : MessagesState {
  switch (action.type) {
    case fromActions.GET_MESSAGES:
      return {
        message: {... action.message}
      };
    default:
      return state;
  }
}
