import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { Observable } from "rxjs";
import { map, filter } from 'rxjs/operators';
import { DesactivarLoadingAction, ActivarLoadingAction } from './shared/ui.accions';
import { Store } from '@ngrx/store';

import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { AppState } from './app.reducer';
import { SearchAllsAction } from './shared/search.accions';
import { Search } from './shared/search.model';


/*import { Users } from './Users';
import { Images } from './Images';
import { Observable } from "rxjs";*/

export interface Images {
  hits: Array<any>;
}

@Injectable({
  providedIn: 'root'
})


export class ApiService {

  random: any[];
  typeImagen: string;
  url: string;
  agency: string = 'AF-TVHB';

  private readonly notifier: NotifierService;


  constructor(private http: HttpClient,
    private httpClient: HttpClient,
    private loadingBar: LoadingBarHttpClientModule,
    private router: Router,
    notifierService: NotifierService,
    private store:Store<AppState>) {
    this.notifier = notifierService;
  }

  getBackground() {
    this.random = ['travel', 'animal', 'nature', 'backgrounds', 'science', 'education'];
    this.typeImagen = this.random[Math.floor(Math.random() * (this.random.length - 1))];
    return this.httpClient.get<Images>('https://pixabay.com/api/?key=10744327-4ccaf62744c9d5403fd0bf75b&q=' + this.typeImagen +'&image_type=photo&pretty=true&orientation=horizontal&webformatURL=_1200&per_page=1_60');
  }

  getLogo() {
    return 'https://viajesinteractiva.com/wp-content/uploads/2015/09/logo1.png';
  }


  getFindHotels(value: string) {
    const limit = 7;
    this.url = 'https://www.bestday.com.co/getSearchJson.aspx?Lenguaje=ESP&ItemTypes=H:'+ limit +'&ItemTypesOrder=H&Filters=&PalabraBuscada=' + value +'&Af='+ this.agency;
    //https://ajax.bestday.com/searchservices/getSearchJson.aspx?callback=jQuery331046642579527738115_1550628652646&PalabraBuscada=fla&Lenguaje=ESP&ItemTypes=D%3A5%2CH%3A5%2CC%3A5&Filters=&Af=497449&_=1550628652647
    //this.url = 'https://ajax.bestday.com/searchservices/getSearchJson.aspx?callback=jQuery17208916521046776325_1429658910138&Lenguaje=ESP&ItemTypes=H:15,D:15,C:15&ItemTypesOrder=H,D,C&Filters=&PalabraBuscada=' + value +'&Af=AF-TVHB';
    const accion = new ActivarLoadingAction();
    this.store.dispatch(accion);

    return this.httpClient.get<Search>(this.url)
    .pipe(filter(data => data.results != null))
    .subscribe((hoteles) => {
      const accion = new SearchAllsAction(hoteles);
      this.store.dispatch(accion);
    }, error => {
      console.log(error);
    })
    ;

  }


}
