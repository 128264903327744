import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { Observable, Subscription } from 'rxjs';
import { map } from "rxjs/operators";

import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ActivarLoadingAction, DesactivarLoadingAction } from '../shared/ui.accions';
import { AppState } from '../app.reducer';
import { SetUserAction, LogOutUserAction } from './auth.actions';
import { SettingsAction, OutSettingsAction } from './settings.actions';

export interface Users {
  "email": string;
  "password": string;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  count$: Observable<number>;
  tokenUser: string;
  initSubscription: Subscription = new Subscription();

  private readonly notifier: NotifierService;

  constructor(private http: HttpClient,
    private httpClient: HttpClient,
    private loadingBar: LoadingBarHttpClientModule,
    private router: Router,
    notifierService: NotifierService,
    private store: Store<AppState>
    ) {
    this.notifier = notifierService;
    this.setStorage();
    }


  initAuthListener() {

    console.log('init');

    const accion = new ActivarLoadingAction();
    this.store.dispatch(accion);

    //console.log('Login de usuario');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Authorization': localStorage.getItem('token')
    });

    const url = 'https://zw.viajesinteractiva.com/admin/api/users/status';

    this.initSubscription = this.httpClient.get<any>(url,
      {
        headers: httpHeaders,
        observe: 'response'
      }
      ).subscribe((usuario: any) => {
      //console.log(usuario);

      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);
      this.store.dispatch(new SetUserAction(usuario.body.result.user));
      this.store.dispatch(new SettingsAction(usuario.body.result.settings) );

    }, error => {

      if (error['status'] === 401) { //Usuario NO existe - Datos errados
        //const msj = error.error['message'];
        const msj = 'La sesión se ha cerrado';
        this.notifier.notify('error', msj);
      }

      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);

      this.logout();

    });

  }


  cancelSubscription() {
    this.initSubscription.unsubscribe();
  }

  getLogin(users: Users): any {

    const accion = new ActivarLoadingAction();
    this.store.dispatch(accion);

    //console.log('Login de usuario');
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    });
    let url = 'https://zw.viajesinteractiva.com/admin/api/users/login';
    this.http.post<Users>(url, users,
      {
        headers: httpHeaders,
        observe: 'response'
      }
    ).subscribe((usuario: any) => {
      //console.log(usuario);
      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);
      this.store.dispatch(new SetUserAction( usuario.body.result));
      this.saveStorage('token', JSON.stringify(usuario.body.result.token_string));
      this.saveStorage('color', JSON.stringify(usuario.body.result.color));
      this.saveStorage('user_id', JSON.stringify(usuario.body.result.id));
      this.saveStorage('role_id', JSON.stringify(usuario.body.result.role_id));

      this.initAuthListener();

      this.router.navigate(['/']);

    }, error => {

      this.notifier.notify('error', error.error['message']);
      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);

    });
  }

  logout() {

    const accion = new LogOutUserAction();
    this.store.dispatch(accion);

    this.store.dispatch(new OutSettingsAction());

    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  isAuth() {

    /*return this.store.select('auth').pipe( map(user => {
      console.log(user);
      if (user.user == null ) {

        this.router.navigate(['/login']);
      }
      return user.user != null

    } ));*/

      /*if (localStorage.getItem('token')) {
        return true
      }else{
        return false
      }*/

    if (localStorage.getItem('token') == null) {

      this.router.navigate(['/login']);
    }
    return localStorage.getItem('token') != null

    //return localStorage.getItem('token') !== null;
  }

  saveStorage(item, value) {
    localStorage.setItem(item, value);
  }

  setStorage() {
    if (localStorage.getItem('token')) {
      this.tokenUser = localStorage.getItem('token');
    }
  }



}
