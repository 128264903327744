import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {AddTagDialogComponent} from './add-tag-dialog/add-tag-dialog.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Contact} from '../../contacts/contact.model';

@Component({
  selector: 'app-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.css']
})
export class AddTagComponent implements OnInit {
  @Input() contact: Contact;

  constructor(public dialog: MatDialog, private http: HttpClient) { }

  ngOnInit() {
  }

  openDialog(event: Event): void {
    const url = 'https://zw.viajesinteractiva.com/admin/api/tags';

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Authorization': localStorage.getItem('token'),
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    });

    this.http.get(url, {headers: httpHeaders}).subscribe((data: any) => {
      const dialogRef = this.dialog.open(AddTagDialogComponent, {
        height: '400px',
        width: '500px',
        data: {
          event: event,
          tags: data,
          contact: this.contact
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    });
  }

}
