import {Message} from "./message.model";

export const GET_MESSAGES = '[Messages]';

export class GetMessagesAction {
  readonly type = GET_MESSAGES;
  constructor(public message: Message) {}
}

export type actions = GetMessagesAction;
