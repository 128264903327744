import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { QuotesState } from './../quotes.reducers';
import { QuotesService } from './../quotes.service';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Quotes } from './../quotes.model';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes-filter.component.html',
  styleUrls: []
})
export class QuotesFilterComponent implements OnInit {

  quotes: MatTableDataSource<Quotes>;
  data: any;
  resultsLength: number;
  id: number;
  pending: number;
  approved: number;
  cancel: number;
  seg: number;

  displayedColumns: string[] = ['id', 'name', 'last_name', 'hotel_one_name', 'user.name', 'status', 'created'];
  subscription: Subscription = new Subscription();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(private store: Store<QuotesState>,
              private quotesService: QuotesService,
              private router: ActivatedRoute
  ) {

    this.router.params
      .pipe(filter( params => !isNaN(params.id)) )
      .subscribe(params => {
        this.id = +params['id'];
      });

    this.quotesService.filterQuotes(this.id);
  }



  applyFilter(filterValue: string) {
    this.quotes.filter = filterValue.trim().toLowerCase();

    /*this.quotes.filterPredicate = (data: any, filter) => {
      const dataStr = JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };*/
  }

  ngOnInit() {
    console.log('INICIO FILTRO');
    this.subscription = this.store.select('quotes')
      .subscribe((data: any) => {

        if (data.quotes != null) {
          this.quotes = new MatTableDataSource<Quotes>(Object.values(data.quotes));
          this.quotes.sort = this.sort;
          this.quotes.paginator = this.paginator;
          this.resultsLength = data.quotes.length;

          console.log(data);

          this.pending=0;
          this.approved=0;
          this.cancel=0;
          this.seg=0;

          for(var key in data) {
            for (var key1 in data[key]) {
              //console.log(data[key][key1])
              //console.log(data[key][key1].status);
              if(data[key][key1].status=='Pendiente'){
                this.pending=this.pending+1;
              }

              if(data[key][key1].status=='Aprobada'){
                this.approved=this.approved+1;
              }

              if(data[key][key1].status=='Rechazada'){
                this.cancel=this.cancel+1;
              }

              if(data[key][key1].status=='Seguimiento'){
                this.seg=this.seg+1;
              }



            }
          }

          //console.log(this.pending);

        }

      });

    this.data = {
      title: 'Listado de Cotizaciones filtradas',
      subTitle: 'Cotizaciones realizadas por asesor.'
    };
  }

  /*ngOnDestroy() {
    this.subscription.unsubscribe();
  }*/

  /*listUsers() {
    console.log('init Users');
    this.usersService.listUsers();

    this.store.select('users').subscribe(data => {

      if (data.users != null) {
        this.usuarios = data['users'];
      }
    });*/





}
