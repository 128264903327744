import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { Observable, Subscription } from 'rxjs';
import { map } from "rxjs/operators";

import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ActivarLoadingAction, DesactivarLoadingAction } from '../shared/ui.accions';
import { UsersState } from './users.reducers';
import { AuthService } from '../auth/auth.service';
import { ListUsersAction, GetUsersAction } from './users.actions';
import { Users } from './users.model';
import { SetUserAction } from '../auth/auth.actions';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private readonly notifier: NotifierService;
  url: string;
  listUserSuscription: Subscription = new Subscription();
  getUserSuscription: Subscription = new Subscription();

  constructor(private http: HttpClient,
    private httpClient: HttpClient,
    private loadingBar: LoadingBarHttpClientModule,
    private router: Router,
    notifierService: NotifierService,
    private store: Store<UsersState>,
    private authService: AuthService) { this.notifier = notifierService;

  }


  listUsers() {
    const accion = new ActivarLoadingAction();
    this.store.dispatch(accion);


    const url = 'https://zw.viajesinteractiva.com/admin/api/users/';

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Authorization': localStorage.getItem('token'),
    });

    this.listUserSuscription = this.httpClient.get<any>(url,
      {
        headers: httpHeaders,
        observe: 'response'
      }
    ).subscribe((usuario: any) => {
      //console.log(usuario);

      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);
      this.store.dispatch(new ListUsersAction(usuario.body.result));

    }, error => {

      if (error['status'] === 401) { //Usuario NO existe - Datos errados
        //const msj = error.error['message'];
        const msj = 'La sesión se ha cerrado';
        this.notifier.notify('error', msj);
      }

      /*const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);*/

      this.authService.logout();

    });
  }

  setUser(users: Users, id: number): any {

    const accion = new ActivarLoadingAction();
    this.store.dispatch(accion);

    if (id) {
      this.url = 'https://zw.viajesinteractiva.com/admin/api/users/' + id;
    } else {
      this.url = 'https://zw.viajesinteractiva.com/admin/api/users/';
    }

    if (!id) {
      this.setPost(this.url, users);
      console.log('post');
    } else {
      this.setPut(this.url, users);
      console.log('put');
    }

  }

  getUser(id: number) {

    const accion = new ActivarLoadingAction();
    this.store.dispatch(accion);

    //console.log('Login de usuario');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Authorization': localStorage.getItem('token'),
    });

    const url = 'https://zw.viajesinteractiva.com/admin/api/users/' + id;

    this.getUserSuscription = this.httpClient.get<any>(url,
      {
        headers: httpHeaders,
        observe: 'response'
      }
    ).subscribe((usuario: any) => {

      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);
      this.store.dispatch(new GetUsersAction(usuario.body.result));

    }, error => {

      if (error['status'] === 401) { //Usuario NO existe - Datos errados
        //const msj = error.error['message'];
        const msj = 'La sesión se ha cerrado';
        this.notifier.notify('error', msj);
      }

      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);

      this.authService.logout();

    });

  }

  setPost(url, users) {

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Authorization': localStorage.getItem('token'),
    });

    this.http.post<Users>(url, users,
      {
        headers: httpHeaders,
        observe: 'response'
      }
    ).subscribe((usuario: any) => {
      //console.log(usuario);

      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);

      this.notifier.notify('success', usuario.body.result['message']);
      this.router.navigate(['/usuarios/']);

    }, error => {

      this.notifier.notify('error', error.error['message']);
      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);

    });
  }


  setPut(url, users) {
    console.log(users);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Authorization': localStorage.getItem('token'),
    });

    this.http.put<Users>(url, users,
      {
        headers: httpHeaders,
        observe: 'response'
      }
    ).subscribe((usuario: any) => {
      //console.log(usuario);

      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);
      //this.store.dispatch(new SetUserAction(usuario.body.result));

      this.notifier.notify('success', usuario.body.result['message']);
      this.router.navigate(['/usuarios/']);

    }, error => {

      this.notifier.notify('error', error.error['message']);
      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);

    });
  }

  cancelarSubscription() {
    this.listUserSuscription.unsubscribe();
    this.getUserSuscription.unsubscribe();
  }

  
}
