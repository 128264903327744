import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LOCALE_ID, NgModule } from '@angular/core';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { IngresoComponent } from './ingreso/ingreso.component';
import { ReportesComponent } from './reportes/reportes.component';
import { DetalleComponent } from './/detalle/detalle.component';
import { FooterComponent } from './shared/footer/footer.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './app.reducer';
import { environment } from '../environments/environment'; // Angular CLI environemnt

import { NotifierModule } from 'angular-notifier';
import { SearchComponent } from './shared/sidebar/search/search.component';
import { ContentComponent } from './shared/sidebar/content/content.component';
import { ItemComponent } from './shared/sidebar/content/item/item.component';
import { SidebarFooterComponent } from './shared/sidebar/sidebar-footer/sidebar-footer.component';

import { FilterPipeModule } from 'ngx-filter-pipe';
import { UsersComponent } from './users/users.component';

import {
  MatCheckboxModule,
  MatInputModule,
  MatCardModule,
  MatTableModule,
  MatSortModule,
  MatTabsModule,
  MatPaginatorModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatStepperModule,
  MatListModule, MatChipsModule, MatTooltipModule, MatMenuModule
} from '@angular/material';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';

import { MatButtonModule } from '@angular/material/button';

import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { QuotesComponent } from './quotes/quotes.component';
import { QuotesFilterComponent } from './quotes/quotes-filter/quotes-filter.component';
import { QuotesDetailComponent } from './quotes/quotes-detail/quotes-detail.component';
import { TitleComponent } from './shared/shared-content/title/title.component';
import { QuotesManualComponent } from './quotes/quotes-manual/quotes-manual.component';
import { ChatComponent } from './wht/chat/chat.component';
import { ContactsComponent } from './wht/chat/contacts/contacts.component';
import { MessagesComponent } from './wht/chat/messages/messages.component';
import {PlyrModule} from "ngx-plyr";
import {UiScrollModule} from "ngx-ui-scroll";
import {MatBadgeModule} from "@angular/material/badge";
import {NewChatDialogComponent} from "./wht/chat/new-chat-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import { SearchBarComponent } from './wht/chat/contacts/search-bar/search-bar.component';
import { AttachmentComponent } from './wht/chat/messages/attachment/attachment.component';
import { ConfirmUploadDialogComponent } from './wht/chat/messages/attachment/confirm-upload-dialog/confirm-upload-dialog.component';
import { ActionsComponent } from './wht/chat/contacts/actions/actions.component';
import { ContactItemComponent } from './wht/chat/contacts/contact-item/contact-item.component';
import {EditContactComponent} from './wht/chat/messages/edit-contact/edit-contact.component';
import { EditContactDialogComponent } from './wht/chat/messages/edit-contact/edit-contact-dialog/edit-contact-dialog.component';
import { AddTagComponent } from './wht/chat/messages/add-tag/add-tag.component';
import { AddTagDialogComponent } from './wht/chat/messages/add-tag/add-tag-dialog/add-tag-dialog.component';
import {FilterByUserDialogComponent} from './wht/chat/contacts/actions/filter-by-user-dialog/filter-by-user-dialog.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
        IngresoComponent,
        ReportesComponent,
        DetalleComponent,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        SearchComponent,
        ContentComponent,
        ItemComponent,
        SidebarFooterComponent,
        UsersComponent,
        UserDetailComponent,
        QuotesComponent,
        QuotesDetailComponent,
        TitleComponent,
        QuotesManualComponent,
        QuotesFilterComponent,
        ChatComponent,
        ContactsComponent,
        MessagesComponent,
        NewChatDialogComponent,
        SearchBarComponent,
        AttachmentComponent,
        ConfirmUploadDialogComponent,
        ActionsComponent,
        ContactItemComponent,
        EditContactComponent,
        EditContactDialogComponent,
        AddTagComponent,
        AddTagDialogComponent,
        FilterByUserDialogComponent
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatStepperModule,
    FilterPipeModule,
    AppRoutingModule,
    LoadingBarHttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatGridListModule,
    NgBootstrapFormValidationModule.forRoot(),
    StoreModule.forRoot(appReducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12
        },
        vertical: {
          position: 'bottom',
          distance: 12,
          gap: 10
        }
      },
      theme: 'material',
      behaviour: {
        autoHide: 5000,
        onClick: false,
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4
      },
      animations: {
        enabled: true,
        show: {
          preset: 'slide',
          speed: 300,
          easing: 'ease'
        },
        hide: {
          preset: 'fade',
          speed: 300,
          easing: 'ease',
          offset: 50
        },
        shift: {
          speed: 300,
          easing: 'ease'
        },
        overlap: 150
      }
    }),
    MatListModule,
    MatChipsModule,
    PlyrModule,
    UiScrollModule,
    MatBadgeModule,
    MatDialogModule,
    MatTooltipModule,
    MatMenuModule
  ],
    exports: [MatButtonModule, MatCheckboxModule, MatGridListModule],
    providers: [],
    bootstrap: [AppComponent],
    entryComponents: [
        NewChatDialogComponent,
        ConfirmUploadDialogComponent,
        EditContactDialogComponent,
        AddTagDialogComponent,
        FilterByUserDialogComponent
    ]
})
export class AppModule { }
