import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-quotes-detail',
  templateUrl: './quotes-detail.component.html',
  styles: []
})
export class QuotesDetailComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;
  hide = true;
  data: any;
  id: number;
  values: string;
  hoteles: any;
  monedas: any;
  medios: any;
  numbers: any;

  constructor(private store: Store<AppState>, private router: ActivatedRoute, 
    private api: ApiService, private formBuilder: FormBuilder) {


    const clients = this.clientsItem();
    const vuelos = this.hotelsItem();
    const hotels = this.hotelsItem();
    const children_ages = this.childrensItem();


    this.formGroup = new FormGroup({
      clients: clients,
      vuelos: new FormArray([vuelos]),
      hotels: new FormArray([hotels]),
      medios: new FormControl('web'),
      night: new FormControl(''),
      adults: new FormControl(''),
      children: new FormControl(''),
      childrenAges: new FormArray([children_ages]),
    });

  }

  ngOnInit() {
    this.getData();
    this.monedas = [
      { 'value': 'cop', 'name': 'PESO COLOMBIANO'},
      { 'value': 'usd', 'name': 'USD'},
      { 'value': 'eur', 'name': 'EUROS'}
    ];
    this.medios = [
      { 'value': 'web', 'name': 'PÁGINA WEB / INTERNET' },
      { 'value': 'tel', 'name': 'TELEFONO' },
      { 'value': 'wht', 'name': 'WHATSAPP' },
      { 'value': 'correo', 'name': 'EMAIL' },
      { 'value': 'ref', 'name': 'REFERIDO' },
      { 'value': 'client', 'name': 'CLIENTE ANTIGUO' },
      { 'value': 'n/a', 'name': 'NINGUNO' }
    ];

  }



  counter(i: number) {
    if (i) {

      for (let a = 0; a <= i; a++) {


        (<FormArray>this.formGroup.controls['childrenAges'])
          .push(this.childrensItem());
        (this.formGroup.get('childrenAges') as FormArray)
          .removeAt(i-1);


      }
      return new Array(i);
    }
  }

  hotelsItem(): FormGroup {
    return this.formBuilder.group({
      name: '',
      price: '',
      currency: ''
    });
  }

  clientsItem(): FormGroup {
    return this.formBuilder.group({
      name: '',
      last_name: '',
      email: '',
      cel: '',
      document: ''
    });
  }

  childrensItem(): FormGroup {
    return this.formBuilder.group({
      edad: ''
    });
  }

  ngOnDestroy() {
  }

  getData() {

    this.data = {
      title : 'Registrar Cotización',
      subTitle : 'Desde esta sección se deberá registrar toda la cotización realizada.'
    };

    this.router.params
      .pipe(filter( params => !isNaN(params.id)) )
      .subscribe(params => {
        this.id = +params['id'];
    });
  }


  onKey(event) {

    this.api.getFindHotels(event.target.value);
    this.store.select('search')
      .pipe(filter(data => data.search != null))
    .subscribe(
      data => {
        this.hoteles = data.search.results;
        console.log(this.hoteles);
      }
    );

  }


  onSubmit() {
    /*this.router.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number
      const datos = new Users({ ... this.formGroup.value });
      if (isNaN(this.id)) {
        this.usersService.setUser(datos, null);
      } else {
        this.usersService.setUser(datos, this.id);
      }
    });*/

    console.log(this.formGroup.value);
  }

  Valor = function Valor(valor) {
    //_classCallCheck(this, Valor);
    this.valor = valor;
  };

  moreQuotes(type) {
    if (type === 'hotels') {
      (<FormArray>this.formGroup.controls['hotels'])
        .push(this.hotelsItem());
    }

    console.log(this.formGroup.value);

  }

  /*addCity(city?: City): void {
    let places = new FormArray([]);
    let name = city ? city.name : '';
    (<FormArray>this.tripForm.controls['cities']).push(
      new FormGroup({
        name: new FormControl(name, Validators.required),
        places: places
      })
    )
  }*/

  removeItem(itemIndex) {
    //this.item.splice(itemIndex, 1);
    //this.formGroup.controls['hotels']
    (this.formGroup.get('hotels') as FormArray)
    .removeAt(itemIndex);
  }



}
