import {ActionReducerMap} from '@ngrx/store';
import * as fromUI from './shared/ui.reducers';
import * as fromAuth from './auth/auth.reducers';
import * as fromUsers from './users/users.reducers';
import * as fromSettings from './auth/settings.reducers';
import * as fromSearch from './shared/search.reducers';
import * as fromQuotes from './quotes/quotes.reducers';
import * as fromContacts from './wht/chat/contacts/contacts.reducers';
import * as fromMessages from './wht/chat/messages/messages.reducer';


export interface AppState {
  ui: fromUI.State;
  auth: fromAuth.AuthState;
  users: fromUsers.UsersState;
  settings: fromSettings.SettingsState;
  search: fromSearch.SearchState;
  quotes: fromQuotes.QuotesState;
  contacts: fromContacts.ContactsState;
  messages: fromMessages.MessagesState;
}


export const appReducers: ActionReducerMap<AppState> = {
  ui: fromUI.uiReducer,
  auth: fromAuth.authReducer,
  users: fromUsers.usersReducer,
  settings: fromSettings.settingsReducer,
  search: fromSearch.searchReducer,
  quotes: fromQuotes.quotesReducer,
  contacts: fromContacts.contactsReducer,
  messages: fromMessages.messagesReducer
};
