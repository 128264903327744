import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { ApiService } from '../../api.service';
import { Quotes } from '../quotes.model';
import { Subscription } from 'rxjs';

import { QuotesService } from '../quotes.service';



@Component({
  selector: 'app-quotes-manual',
  templateUrl: './quotes-manual.component.html',
  styleUrls: ['./quotes-manual.component.css']
})
export class QuotesManualComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;
  hide = true;
  data: any;
  id: number;
  values: string;
  user_id: number;
  status: string;

  hoteles: string;
  hoteles_neto: number;
  tiquetes: string;

  ta_wingo: string;
  ta_nacional: string;
  ta_internacional: string;

  traslados: string;

  tours: string;
  asistencia: string;
  turismo: string;
  otros: string;

  promocion: string;
  total: string;
  total_chld: string;
  neto: number;
  total_neto: number;

  hotel_neto: number;
  neto_hotel_one: number;
  neto_hotel_two: number;
  neto_hotel_three: number;
  neto_hotel_four: number;
  neto_hotel_five: number;

  util = 15;
  promo = 10;

  types = [{ 'name': 'Nacional', 'value': 'Nacional'}, {'name': 'Internacional', 'value': 'Internacional'}];
  currency = [{ 'name': 'COP', 'value': 'COP' }, { 'name': 'USD', 'value': 'USD' }];

  estados = [{'name': 'Pendiente', 'value': 'Pendiente'}, {'name': 'Rechazada', 'value': 'Rechazada'}, {'name': 'Seguimiento', 'value': 'Seguimiento'},
  {'name': 'Aprobada', 'value': 'Aprobada'}];

  quote: Subscription = new Subscription();
  dataQuote: any;

  constructor(private quotesService: QuotesService, private store: Store<AppState>, private router: ActivatedRoute,
    private api: ApiService, private formBuilder: FormBuilder) {


    this.store.select('auth')
      .pipe(
        filter(set => set.user != null)
      )
      .subscribe(
        set => {
          this.user_id = set.user.id;
        }
      );



    const numericRegex = '^\\d*$';

    this.formGroup = new FormGroup({
      type: new FormControl('Internacional'),
      currency: new FormControl(''),
      trm: new FormControl(''),
      name: new FormControl(''),
      last_name: new FormControl(''),
      email: new FormControl(''),
      phone_number: new FormControl('', [Validators.required, Validators.minLength(7), Validators.pattern(numericRegex)]),
      document: new FormControl(''),
      children_number: new FormControl('0'),
      adult_number: new FormControl('0'),
      net_amount: new FormControl('0'),
      ta_wingo: new FormControl('0'),
      ta_international: new FormControl('0'),
      ta_national: new FormControl('0'),
      hotel_one_name: new FormControl(''),
      hotel_one_amount: new FormControl('0'),
      hotel_two_name: new FormControl(''),
      hotel_two_amount: new FormControl('0'),
      hotel_three_name: new FormControl(''),
      hotel_three_amount: new FormControl('0'),
      hotel_four_name: new FormControl(''),
      hotel_four_amount: new FormControl('0'),
      hotel_five_name: new FormControl(''),
      hotel_five_amount: new FormControl('0'),
      transfer: new FormControl('0'),
      tours: new FormControl('0'),
      medical_assistance: new FormControl('0'),
      cards: new FormControl('0'),
      others: new FormControl('0'),
      id: new FormControl(''),
      status: new FormControl(''),
      user_id: new FormControl(''),
      description: new FormControl('')
    });


    }

  ngOnInit() {
    this.getData();

    this.onChanges();
    /*this.monedas = [
      { 'value': 'cop', 'name': 'PESO COLOMBIANO'},
      { 'value': 'usd', 'name': 'USD'},
      { 'value': 'eur', 'name': 'EUROS'}
    ];
    this.medios = [
      { 'value': 'web', 'name': 'PÁGINA WEB / INTERNET' },
      { 'value': 'tel', 'name': 'TELEFONO' },
      { 'value': 'wht', 'name': 'WHATSAPP' },
      { 'value': 'correo', 'name': 'EMAIL' },
      { 'value': 'ref', 'name': 'REFERIDO' },
      { 'value': 'client', 'name': 'CLIENTE ANTIGUO' },
      { 'value': 'n/a', 'name': 'NINGUNO' }
    ];*/

  }

  ngOnDestroy() {
    this.quote.unsubscribe();
  }

  getData() {

    /*this.data = {
      title : 'Registrar Cotización',
      subTitle : ''
    };

    this.router.params
      .pipe(filter( params => !isNaN(params.id)) )
      .subscribe(params => {
        this.id = +params['id'];
    });*/



      //this.getStatus();

      this.router.params.subscribe(params => {

        this.id = +params['id']; // (+) converts string 'id' to a number
        if (isNaN(this.id)) {
          this.data = {
            title: 'Registrar Cotización',
            subTitle: ''
          };
          this.status='Pendiente';
        } else {
          this.data = {
            title: 'Editar Cotización',
            subTitle: ''
          };
          this.quotesService.getQuote(this.id);


          this.quote = this.store.select('quotes')
            .pipe(filter(quote => quote.quotes != null))
            .pipe(filter(quote => typeof quote.quotes.id !== 'undefined'))
            .subscribe(
              quote => {
                console.log(quote);

                this.dataQuote = {
                  'id': quote.quotes.id,
                  'type': quote.quotes.type,
                  'currency': quote.quotes.currency,
                  'trm': quote.quotes.trm,
                  'phone_number': quote.quotes.phone_number,
                  'document': quote.quotes.document,
                  'children_number': quote.quotes.children_number,
                  'adult_number': quote.quotes.adult_number,
                  'net_amount': quote.quotes.net_amount,
                  'ta_wingo': quote.quotes.ta_wingo,
                  'ta_international': quote.quotes.ta_international,
                  'ta_national': quote.quotes.ta_national,
                  'hotel_one_name': quote.quotes.hotel_one_name,
                  'hotel_one_amount': quote.quotes.hotel_one_amount,
                  'hotel_two_name': quote.quotes.hotel_two_name,
                  'hotel_two_amount': quote.quotes.hotel_two_amount,
                  'hotel_three_name': quote.quotes.hotel_three_name,
                  'hotel_three_amount': quote.quotes.hotel_three_amount,
                  'hotel_four_name': quote.quotes.hotel_four_name,
                  'hotel_four_amount': quote.quotes.hotel_four_amount,
                  'hotel_five_name': quote.quotes.hotel_five_name,
                  'hotel_five_amount': quote.quotes.hotel_five_amount,
                  'transfer': quote.quotes.transfer,
                  'tours': quote.quotes.tours,
                  'medical_assistance': quote.quotes.medical_assistance,
                  'cards': quote.quotes.cards,
                  'others': quote.quotes.others,
                  'name': quote.quotes.name,
                  'last_name': quote.quotes.last_name,
                  'email': quote.quotes.email,
                  'status': quote.quotes.status,
                  'user_id': quote.quotes.user_id,
                  'description': quote.quotes.description
                };

                this.status = quote.quotes.status;

                if (typeof quote.quotes.util !== 'undefined' && quote.quotes.util != null) {
                  this.util = quote.quotes.util;
                }

                this.formGroup.setValue(this.dataQuote);
              }
            );


        }
      });
  }


  format1(n, currency) {
    return currency + " " + n.toFixed(0).replace(/./g, function (c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
    });
  }


  onSubmit() {
    this.router.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number
      const datos = new Quotes({ ... this.formGroup.value });
      if (isNaN(this.id)) {
        datos.user_id = this.user_id;
        datos.status ='Pendiente';
        datos.util = this.util;
        this.quotesService.setQuote(datos, null);
        this.formGroup.reset();
      } else {
        datos.util = this.util;
        this.quotesService.setQuote(datos, this.id);
      }
    });
  }

  porcent(data, trm) {

      if (trm) {
        data = parseInt(data) * parseInt(trm);
      }
    return parseInt(data) * this.util / 100 + parseInt(data);

  }

  onChanges(): void {
    this.formGroup.valueChanges.subscribe(val => {

      const currencySelect = (val.currency ? val.currency:null);
      const trmSelect = (val.trm ? val.trm:1);

      // Tarifa HOTEL
      val.hotel_one_amount = (val.hotel_one_amount ? val.hotel_one_amount : 0);
      val.hotel_two_amount = (val.hotel_two_amount ? val.hotel_two_amount : 0);
      val.hotel_three_amount = (val.hotel_three_amount ? val.hotel_three_amount : 0);
      val.hotel_four_amount = (val.hotel_four_amount ? val.hotel_four_amount : 0);
      val.hotel_five_amount = (val.hotel_five_amount ? val.hotel_five_amount : 0);


      val.transfer = (val.transfer ? val.transfer : 0);
      val.tours = (val.tours ? val.tours : 0);
      val.medical_assistance = (val.medical_assistance ? val.medical_assistance : 0);
      val.cards = (val.cards ? val.cards : 0);
      val.others = (val.others ? val.others : 0);
      val.ta_wingo = (val.ta_wingo ? val.ta_wingo : 0);
      val.ta_international = (val.ta_international ? val.ta_international : 0);
      val.ta_national = (val.ta_national ? val.ta_national : 0);

      let hotel_neto = this.porcent(val.hotel_one_amount, trmSelect)+
        this.porcent(val.hotel_two_amount, trmSelect)+
        this.porcent(val.hotel_three_amount, trmSelect)+
        this.porcent(val.hotel_four_amount, trmSelect)+
        this.porcent(val.hotel_five_amount, trmSelect);

      if (val.type == "Nacional") {
          this.util = 15;
      } else{
        this.util = 18;
      }

      this.neto_hotel_one = this.porcent(val.hotel_one_amount, trmSelect);
      this.neto_hotel_two = this.porcent(val.hotel_two_amount, trmSelect);
      this.neto_hotel_three = this.porcent(val.hotel_three_amount, trmSelect);
      this.neto_hotel_four = this.porcent(val.hotel_four_amount, trmSelect);
      this.neto_hotel_five = this.porcent(val.hotel_five_amount, trmSelect);

      this.hoteles_neto = hotel_neto;

      this.hoteles = this.format1(hotel_neto, '$');
      this.tiquetes = this.format1(parseInt(val.net_amount), '$');

      this.ta_nacional = this.format1(parseInt(val.ta_national), '$');
      this.ta_internacional = this.format1(parseInt(val.ta_international), '$');
      this.ta_wingo = this.format1(parseInt(val.ta_wingo), '$');

      this.neto = this.hoteles_neto + parseInt(val.net_amount) + this.porcent(val.transfer, trmSelect) + this.porcent(val.tours, trmSelect) + this.porcent(val.medical_assistance, trmSelect)
        + this.porcent(val.cards, trmSelect) + this.porcent(val.others, trmSelect) + parseInt(val.ta_wingo) + parseInt(val.ta_international) + parseInt(val.ta_national);

      const valueOne = parseInt(val.ta_wingo) * 8 / 100;
      const valueTwo = parseInt(val.ta_international) * 8 / 100;
      const valueThree = parseInt(val.ta_national) * 8 / 100;
      const valueFour = parseInt(val.net_amount) * 8 / 100;


      this.total_neto = (this.neto - val.net_amount ) * this.promo / 100 + this.neto + valueOne + valueTwo + valueThree +valueFour;

      this.traslados = this.format1(this.porcent(val.transfer, trmSelect), '$');
      this.tours = this.format1(this.porcent(val.tours, trmSelect), '$');
      this.asistencia = this.format1(this.porcent(val.medical_assistance, trmSelect), '$');
      this.turismo = this.format1(this.porcent(val.cards, trmSelect), '$');
      this.otros = this.format1(this.porcent(val.others, trmSelect), '$');

      this.total_chld = this.format1(this.total_neto - this.hoteles_neto, '$');

        this.total = this.format1(this.total_neto, '$');
        this.promocion = this.format1(this.neto, '$');

      //this.total = this.total_neto;
      //this.promocion = this.neto;

    });
  }


  /*addCity(city?: City): void {
    let places = new FormArray([]);
    let name = city ? city.name : '';
    (<FormArray>this.tripForm.controls['cities']).push(
      new FormGroup({
        name: new FormControl(name, Validators.required),
        places: places
      })
    )
  }*/


}
