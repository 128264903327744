import {Component, OnInit} from '@angular/core';
import {NewChatDialogComponent} from '../../new-chat-dialog.component';
import {MatDialog} from '@angular/material';
import {MessageService} from '../../messages/message.service';
import {ContactService} from '../contact.service';
import {ChatService} from '../../chat.service';
import * as _ from 'lodash';
import {Contact} from '../contact.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AddTagDialogComponent} from '../../messages/add-tag/add-tag-dialog/add-tag-dialog.component';
import {FilterByUserDialogComponent} from './filter-by-user-dialog/filter-by-user-dialog.component';
import {AuthService} from '../../../../auth/auth.service';

@Component({
    selector: 'app-actions',
    templateUrl: './actions.component.html',
    styleUrls: ['./actions.component.css']
})
export class ActionsComponent implements OnInit {

    public currentContacts: Contact[] = null;

    public tags = [];

    public roleId;

    constructor(
        public messageService: MessageService,
        public contactService: ContactService,
        public chatService: ChatService,
        public authService: AuthService,
        public dialog: MatDialog,
        private http: HttpClient
    ) {
    }

    ngOnInit() {
      this.getTags();
      this.roleId = localStorage.getItem('role_id');
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(NewChatDialogComponent, {
            height: '400px',
            width: '600px',
            data: {
                messages: this.chatService.messages,
                contacts: this.chatService.contacts,
                selectedContact: this.chatService.selectedContact
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    reloadChat() {
        localStorage.removeItem('is_my_chats_filter_active');
        this.contactService.getContacts();

        if (this.chatService.selectedContact) {
            this.messageService.getMessages(this.chatService.selectedContact);
        }
    }

    filterChats() {
      localStorage.setItem('is_my_chats_filter_active', 'true');

      const $user_id =  localStorage.getItem('user_id');

      localStorage.setItem('filter_user_id', $user_id);

      const url = 'https://zw.viajesinteractiva.com/admin/api/messages?u=' + $user_id;

      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Authorization': localStorage.getItem('token'),
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      });


      this.http.get(url, {headers: httpHeaders}).subscribe(response => {
        console.log(response);

        this.chatService.contacts.splice(0, this.chatService.contacts.length);

        for (let i = 0; i < response['result'].length; i++) {
          this.chatService.contacts.push(response['result'][i]);
        }
      });
        /*this.currentContacts = this.chatService.contacts;

        let filtered = [];

        filtered = _.filter(this.chatService.contacts, function (x) {
            if (x.metadata.user !== null) {
                return x.metadata.user.color === JSON.parse(localStorage.getItem('color'));
            }
        });

        this.chatService.contacts = filtered;*/
    }

    viewList() {
        this.chatService.contacts = this.currentContacts;
        this.currentContacts = null;
    }

    getTags() {
      const url = 'https://zw.viajesinteractiva.com/admin/api/tags';

      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Authorization': localStorage.getItem('token'),
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      });

      this.http.get(url, {headers: httpHeaders}).subscribe((data: any) => {
          this.tags = data.result;
      });
    }

    filterByTag($tag_id) {
      let url = 'https://zw.viajesinteractiva.com/admin/api/messages?t=' + $tag_id;

      if (localStorage.getItem('is_my_chats_filter_active')) {
          url += '&user_id=' + localStorage.getItem('filter_user_id');
      }

      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Authorization': localStorage.getItem('token'),
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      });

      this.http.get(url, {headers: httpHeaders}).subscribe(response => {
        this.chatService.contacts.splice(0, this.chatService.contacts.length);

        for (let i = 0; i < response['result'].length; i++) {
          this.chatService.contacts.push(response['result'][i]);
        }
      });
    }

   openFilterByUserDialog(event: Event): void {
    const url = 'https://zw.viajesinteractiva.com/admin/api/users';

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Authorization': localStorage.getItem('token'),
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    });

    this.http.get(url, {headers: httpHeaders}).subscribe((data: any) => {
      const dialogRef = this.dialog.open(FilterByUserDialogComponent, {
        height: '400px',
        width: '500px',
        data: {
          event: event,
          users: data,
          contacts: this.chatService.contacts
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    });
  }
}
