import {Component, Input, OnInit} from '@angular/core';
import {Contact} from '../contact.model';
import * as Color from 'color';

@Component({
    selector: 'app-contact-item',
    templateUrl: './contact-item.component.html',
    styleUrls: ['./contact-item.component.css']
})
export class ContactItemComponent implements OnInit {

    @Input() contact: Contact;

    public lastContact: Contact = null;

    constructor() {
    }

    ngOnInit() {
    }

    typeOf(value){
      return typeof value;
    }

}
