import {Injectable} from '@angular/core';
import {ActivarLoadingAction, DesactivarLoadingAction} from '../../../shared/ui.accions';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {MessagesState} from './messages.reducer';
import {Observable, Subject, Subscription} from 'rxjs';
import {Message} from './message.model';
import {GetMessagesAction} from './messages.actions';
import {IDatasource} from 'ngx-ui-scroll';
import {split} from 'ts-node';
import {Contact} from '../contacts/contact.model';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {ContactService} from '../contacts/contact.service';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    messagesSubscription: Subscription = new Subscription();

    phoneNumber: string;

    constructor(
        private http: HttpClient,
        private store: Store<MessagesState>,
        private contactService: ContactService
    ) {
    }

    getMessages(contact: Contact) {
        contact.metadata['unread_messages'] = 0;

        const action = new ActivarLoadingAction();
        this.store.dispatch(action);

        this.phoneNumber = contact.id;

        const url = 'https://zw.viajesinteractiva.com/admin/api/messages/' + contact.id;

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Authorization': localStorage.getItem('token'),
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
        });

        const response = this.http.get<Message[]>(url, {
            headers: httpHeaders,
            observe: 'response'
        });

        this.messagesSubscription = response.subscribe((messages: any) => {
            // tslint:disable-next-line:no-shadowed-variable
            const action = new DesactivarLoadingAction();
            this.store.dispatch(action);
            this.store.dispatch(new GetMessagesAction(messages.body.result));
        });

        return response;
    }

    sendMessage(messages: Object[], message: string, contact, type, file = null) {
        let temp = new Message({
            user_id: null,
            msj: message,
            send_from: '',
            send_to: contact.id.split('@')[0],
            name_wht: null,
            type: 'chat',
            chat_id: contact.id,
            read: null,
            user_read: null,
            number: null,
            from_me: true,
            users_read: null,
            send_time: null,
            user: null,
            whts_track: null
        });

        const url = 'https://zw.viajesinteractiva.com/admin/api/messages';

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Authorization': localStorage.getItem('token'),
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
        });

        if (type === 'chat') {
            messages.push(temp);

            this.http.post(url,
                {body: message, chatId: contact.id, type: type, from_me: true},
                {headers: httpHeaders, observe: 'response'}
            ).subscribe();
        }

        if (type === 'file') {
            const formData = new FormData();
            formData.append('chatId', contact.id);
            formData.append('type', type);
            formData.append('from_me', 'true');
            formData.append('file', file);

            this.http.post(url, formData, {
                    headers: {
                        'X-Authorization': localStorage.getItem('token'),
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache'
                    }, observe: 'response'
                }
            ).subscribe(response => {
                temp.msj = response.body['result'].msj;
                temp.type = response.body['result'].type;
                messages.push(temp);
            });
        }
    }

    search(query: Observable<string>) {
        return query.pipe(debounceTime(400))
            .pipe(distinctUntilChanged())
            .pipe(switchMap(q => this.searchEntries(q)));
    }

    searchEntries($query) {
        const url = 'https://zw.viajesinteractiva.com/admin/api/messages?q=' + $query;

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Authorization': localStorage.getItem('token'),
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
        });

        return this.http.get(url, {headers: httpHeaders});
    }
}
