export class Message {
  public user_id: number;
  public msj: string;
  public send_from: string;
  public send_to: string;
  public name_wht: string;
  public type: string;
  public chat_id: string;
  public read: boolean;
  public user_read: number;
  public number: number;
  public from_me: boolean;
  public users_read: [];
  public send_time: string;
  public user: [];
  public whts_track: [];

  constructor(obj: DataObj) {
    this.user_id = obj && obj.user_id || null;
    this.msj = obj && obj.msj || null;
    this.send_from = obj && obj.send_from || null;
    this.send_to = obj && obj.send_to || null;
    this.name_wht = obj && obj.name_wht || null;
    this.type = obj && obj.type || null;
    this.chat_id = obj && obj.chat_id || null;
    this.read = obj && obj.read || null;
    this.user_read = obj && obj.user_read || null;
    this.users_read = obj && obj.users_read || null;
    this.number = obj && obj.number || null;
    this.from_me = obj && obj.from_me || null;
    this.send_time = obj && obj.send_time || null;
    this.user = obj && obj.user || null;
    this.whts_track = obj && obj.whts_track || null;
  }
}

interface DataObj {
  user_id: number;
  msj: string;
  send_from: string;
  send_to: string;
  name_wht: string;
  type: string;
  chat_id: string;
  read: boolean;
  user_read: number;
  number: number;
  from_me: boolean;
  users_read: [];
  send_time: string;
  user: [];
  whts_track : [];
}
