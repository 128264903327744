import { Action } from "@ngrx/store";
import { Users } from "./users.model";

export const LIST_USER = '[Users] Listado de usuarios';
export const GET_USER = '[Users] Datos de usuario';
export const EMPTY_USER = '[Users] Empty usuarios';

export class ListUsersAction implements Action { 
      readonly type = LIST_USER;
      constructor(public user: Users) { }
}
export class GetUsersAction implements Action {
      readonly type = GET_USER;
      constructor(public user: Users) { }
}
export class EmptyUsersAction implements Action {
      readonly type = EMPTY_USER;
}
export type acciones = ListUsersAction | GetUsersAction | EmptyUsersAction;