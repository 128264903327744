export class Quotes {
      public name: string;
      public last_name: string;
      public phone_number: number;
      public email: string;
      public id: number;
      public children_number: number;
      public adult_number: number;
      public net_amount: number;
      public ta_wingo: number;
      public ta_international: number;
      public ta_national: number;
      public hotel_one_name: string;
      public hotel_one_amount: number;
      public hotel_two_name: string;
      public hotel_two_amount: number;
      public hotel_three_name: string;
      public hotel_three_amount: number;
      public hotel_four_name: string;
      public hotel_four_amount: number;
      public hotel_five_name: string;
      public hotel_five_amount: number;
      public transfer: number;
      public tours: number;
      public medical_assistance: number;
      public cards: number;
      public others: number;
      public type: string;
      public currency: string;
      public trm: number;
      public document: number;
      public user_id: number;
      public status: string;
      public description: string;
      public util: number;


      constructor(obj: DataObj) {
            this.name = obj && obj.name || null;
            this.last_name = obj && obj.last_name || null;
            this.phone_number = obj && obj.phone_number || null;
            this.email = obj && obj.email || null;
            this.children_number = obj && obj.children_number || null;
            this.document = obj && obj.document || null;
            this.adult_number = obj && obj.adult_number || null;
            this.net_amount = obj && obj.net_amount || null;
            this.ta_wingo = obj && obj.ta_wingo || null;
            this.ta_international = obj && obj.ta_international || null;
            this.ta_national = obj && obj.ta_national || null;
            this.hotel_one_name = obj && obj.hotel_one_name || null;
            this.hotel_one_amount = obj && obj.hotel_one_amount || null;
            this.hotel_two_name = obj && obj.hotel_two_name || null;
            this.hotel_two_amount = obj && obj.hotel_two_amount || null;
            this.hotel_three_name = obj && obj.hotel_three_name || null;
            this.hotel_three_amount = obj && obj.hotel_three_amount || null;
            this.hotel_four_name = obj && obj.hotel_four_name || null;
            this.hotel_four_amount = obj && obj.hotel_four_amount || null;
            this.hotel_five_name = obj && obj.hotel_five_name || null;
            this.hotel_five_amount = obj && obj.hotel_five_amount || null;
            this.transfer = obj && obj.transfer || null;
            this.tours = obj && obj.tours || null;
            this.medical_assistance = obj && obj.medical_assistance || null;
            this.cards = obj && obj.cards || null;
            this.others = obj && obj.others || null;
            this.type = obj && obj.type || null;
            this.currency = obj && obj.currency || null;
            this.trm = obj && obj.trm || null;
            this.user_id = obj && obj.user_id || null;
            this.status = obj && obj.status || null;
            this.description = obj && obj.description || null;
            this.util = obj && obj.util || null;

            if (obj.id) {
                  this.id = obj.id;
            }
      }
}

interface DataObj {
      id: number;
      email: string;
      name: string;
      document: number;
      last_name: string;
      phone_number: number;
      children_number: number;
      adult_number: number;
      net_amount: number;
      ta_wingo: number;
      ta_international: number;
      ta_national: number;
      hotel_one_name: string;
      hotel_one_amount: number;
      hotel_two_name: string;
      hotel_two_amount: number;
      hotel_three_name: string;
      hotel_three_amount: number;
      hotel_four_name: string;
      hotel_four_amount: number;
      hotel_five_name: string;
      hotel_five_amount: number;
      transfer: number;
      tours: number;
      medical_assistance: number;
      cards: number;
      others: number;
      type: string;
      currency: string;
      trm: number;
      user_id: number;
      status: string;
      description: string;
      util: number;
}
