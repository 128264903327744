import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { Observable, Subscription } from 'rxjs';
import { map } from "rxjs/operators";

import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ActivarLoadingAction, DesactivarLoadingAction } from '../shared/ui.accions';

import { AuthService } from '../auth/auth.service';

import { QuotesState } from './quotes.reducers';
import { ListQuotesAction, GetQuotesAction, FilterQuotesAction } from './quotes.actions';
import { Quotes } from './quotes.model';


@Injectable({
  providedIn: 'root'
})
export class QuotesService {

  private readonly notifier: NotifierService;
  url: string;
  listQuoteSuscription: Subscription = new Subscription();
  filterQuoteSuscription: Subscription = new Subscription();
  getQuoteSuscription: Subscription = new Subscription();

  constructor(private http: HttpClient,
    private httpClient: HttpClient,
    private loadingBar: LoadingBarHttpClientModule,
    private router: Router,
    notifierService: NotifierService,
    private store: Store<QuotesState>,
    private authService: AuthService) { this.notifier = notifierService;

  }


  listQuotes() {
    const accion = new ActivarLoadingAction();
    this.store.dispatch(accion);


    const url = 'https://zw.viajesinteractiva.com/admin/api/quotes/';

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Authorization': localStorage.getItem('token'),
    });

    this.listQuoteSuscription = this.httpClient.get<any>(url,
      {
        headers: httpHeaders,
        observe: 'response'
      }
    ).subscribe((quote: any) => {
      //console.log(usuario);

      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);
      this.store.dispatch(new ListQuotesAction(quote.body.result));

    }, error => {

      if (error['status'] === 401) { //Usuario NO existe - Datos errados
        //const msj = error.error['message'];
        const msj = 'La sesión se ha cerrado';
        this.notifier.notify('error', msj);
      }

      /*const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);*/

      this.authService.logout();

    });
  }

  filterQuotes(id: number) {
    const accion = new ActivarLoadingAction();
    this.store.dispatch(accion);


    const url = 'https://zw.viajesinteractiva.com/admin/api/quotes/filter/'+id;

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Authorization': localStorage.getItem('token'),
    });

    this.filterQuoteSuscription = this.httpClient.get<any>(url,
      {
        headers: httpHeaders,
        observe: 'response'
      }
    ).subscribe((quote: any) => {
      //console.log(usuario);

      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);
      this.store.dispatch(new FilterQuotesAction(quote.body.result));

    }, error => {

      if (error['status'] === 401) { //Usuario NO existe - Datos errados
        //const msj = error.error['message'];
        const msj = 'La sesión se ha cerrado';
        this.notifier.notify('error', msj);
      }

      /*const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);*/

      this.authService.logout();

    });
  }

  setQuote(quotes: Quotes, id: number): any {

    const accion = new ActivarLoadingAction();
    this.store.dispatch(accion);

    if (id) {
      this.url = 'https://zw.viajesinteractiva.com/admin/api/quotes/' + id;
    } else {
      this.url = 'https://zw.viajesinteractiva.com/admin/api/quotes/';
    }

    if (!id) {
      this.setPost(this.url, quotes);
      console.log('post');
    } else {
      this.setPut(this.url, quotes);
      console.log('put');
    }

  }

  getQuote(id: number) {

    const accion = new ActivarLoadingAction();
    this.store.dispatch(accion);

    //console.log('Login de usuario');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Authorization': localStorage.getItem('token'),
    });

    const url = 'https://zw.viajesinteractiva.com/admin/api/quotes/' + id;

    this.getQuoteSuscription = this.httpClient.get<any>(url,
      {
        headers: httpHeaders,
        observe: 'response'
      }
    ).subscribe((quote: any) => {

      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);
      this.store.dispatch(new GetQuotesAction(quote.body.result));

    }, error => {

      if (error['status'] === 401) { //Usuario NO existe - Datos errados
        //const msj = error.error['message'];
        const msj = 'La sesión se ha cerrado';
        this.notifier.notify('error', msj);
      }

      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);

      this.authService.logout();

    });

  }

  setPost(url, quotes) {

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Authorization': localStorage.getItem('token'),
    });

    this.http.post<Quotes>(url, quotes,
      {
        headers: httpHeaders,
        observe: 'response'
      }
    ).subscribe((quote: any) => {
      //console.log(usuario);

      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);

      this.notifier.notify('success', quote.body.result['message']);
          this.router.navigate(['/cotizaciones/']);


    }, error => {

      this.notifier.notify('error', error.error['message']);
      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);

    });
  }


  setPut(url, quotes) {
        console.log(quotes);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Authorization': localStorage.getItem('token'),
    });

        this.http.put<Quotes>(url, quotes,
      {
        headers: httpHeaders,
        observe: 'response'
      }
    ).subscribe((quote: any) => {
      //console.log(usuario);

      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);
      //this.store.dispatch(new SetUserAction(usuario.body.result));
      this.notifier.notify('success', quote.body.result);
      this.router.navigate(['/cotizaciones/manual/']);

    }, error => {

      this.notifier.notify('error', error.error['message']);
      const accion = new DesactivarLoadingAction();
      this.store.dispatch(accion);

    });
  }

  cancelarSubscription() {
    this.listQuoteSuscription.unsubscribe();
    this.filterQuoteSuscription.unsubscribe();
    this.getQuoteSuscription.unsubscribe();
  }


}
