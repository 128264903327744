import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {of, Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {ContactsState} from "./contacts.reducers";
import {ActivarLoadingAction, DesactivarLoadingAction} from "../../../shared/ui.accions";
import {ListContactsAction} from "./contacts.actions";
import {Contact} from "./contact.model";

@Injectable({
  providedIn: 'root'
})
export class ContactService implements OnDestroy{

  listContactSubscription : Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private store: Store<ContactsState>
  ) { }

  getContacts() {
    const action = new ActivarLoadingAction();
    this.store.dispatch(action);

    const url = 'https://zw.viajesinteractiva.com/admin/api/chats/';

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Authorization': localStorage.getItem('token'),
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    });

    this.http.get<Contact[]>(url, {
      headers: httpHeaders,
      observe: 'response'
    }).subscribe((contacts: any) => {
      const action = new DesactivarLoadingAction();
      this.store.dispatch(action);
      this.store.dispatch(new ListContactsAction(contacts.body.result));
    });
  }

  ngOnDestroy(): void {
    this.listContactSubscription.unsubscribe();
  }
}
