import { Action } from "@ngrx/store";

export const ACTIVAR_LOADING = '[UI Loading] Cargando...';
export const DESACTIVAR_LOADING = '[UI Loading] Fin de carga...';
export const OPEN_ITEM = '[UI Item] Item Abierto';

export class ActivarLoadingAction implements Action {
      readonly type = ACTIVAR_LOADING;
}

export class DesactivarLoadingAction implements Action {
      readonly type = DESACTIVAR_LOADING;
}

export class OpenItemAction implements Action {
      readonly type = OPEN_ITEM;
}

export type acciones = ActivarLoadingAction | DesactivarLoadingAction | OpenItemAction;