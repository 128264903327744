import * as fromActions from './users.actions';
import { Users } from './users.model';

export interface UsersState {
      users: Users;
}

const estadoInicial: UsersState = {
      users: null
}

export function usersReducer(state = estadoInicial, action: fromActions.acciones): UsersState {
      switch (action.type) {
            case fromActions.LIST_USER:
                  return {
                        users: { ... action.user }
                  };
            case fromActions.GET_USER:
                  return {
                        users: action.user
                  };
            case fromActions.EMPTY_USER:
                  return {
                        users: null
                  };
            default:
                  return state;
      }
}
