import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { QuotesState } from './quotes.reducers';
import { QuotesService } from './quotes.service';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Quotes } from './quotes.model';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: []
})
export class QuotesComponent implements OnInit {

  quotes: MatTableDataSource<Quotes>;
  data: any;
  resultsLength: number;
  displayedColumns: string[] = ['id', 'name', 'last_name', 'hotel_one_name', 'user.name', 'status', 'created'];
  subscription: Subscription = new Subscription();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(private store: Store<QuotesState>,
    private quotesService: QuotesService) {
    this.quotesService.listQuotes();

    console.log('MANUAL');
  }

  applyFilter(filterValue: string) {
    this.quotes.filter = filterValue.trim().toLowerCase();

    /*this.quotes.filterPredicate = (data: any, filter) => {
      const dataStr = JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };*/
  }

  ngOnInit() {
    this.subscription = this.store.select('quotes')
    .subscribe((data: any) => {

      if (data.quotes != null) {
        this.quotes = new MatTableDataSource<Quotes>(Object.values(data.quotes));
        this.quotes.sort = this.sort;
        this.quotes.paginator = this.paginator;
        this.resultsLength = data.quotes.length;
      }

    });

    this.data = {
      title: 'Listado de Cotizaciones',
      subTitle: 'Listado de todas las cotizaciones realizadas.'
    };
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /*listUsers() {
    console.log('init Users');
    this.usersService.listUsers();

    this.store.select('users').subscribe(data => {

      if (data.users != null) {
        this.usuarios = data['users'];
      }
    });*/





}
