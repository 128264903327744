import {Component, Inject, Input} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MessageService} from './messages/message.service';
import {Message} from './messages/message.model';
import {Contact} from './contacts/contact.model';
import {ChatService} from './chat.service';
import {Store} from '@ngrx/store';
import {MessagesState} from './messages/messages.reducer';

@Component({
    selector: 'new-chat-dialog',
    templateUrl: 'new-chat-dialog.html',
})
export class NewChatDialogComponent {

    message: string;

    constructor(public newChatDialogRef: MatDialogRef<NewChatDialogComponent>,
                private messageService: MessageService,
                private chatService: ChatService,
                private store: Store<MessagesState>,
                @Inject(MAT_DIALOG_DATA) public data: any) {

    }

    @Input() messages: Message[];

    onNoClick(): void {
        this.newChatDialogRef.close();
    }

    sendMessage(phoneNumber, message) {
        const contact = new Contact(
            `${phoneNumber}@c.us`,
            phoneNumber,
            null,
            [],
            {type: 'chat', body: message, metadata: [{unread_messages: 0}]},
            null,
            null
        );

        const contacts = this.data.contacts;

        if (contacts.filter(x => x.id === contact.id).length > 0) {
            for (let i = 0; i < contacts.length; i++) {
                if (contacts[i].id === contact.id) {
                    contacts[i].message.body = contact.message['body'];
                    this.chatService.selectedContact = contacts[i];
                    contacts.unshift(contacts.splice(i, 1)[0]);
                    break;
                }
            }
        } else {
            contacts.unshift(contact);
        }


/*
      this.messageService.getMessages(contact);
*/

      /*this.store.select('messages').subscribe((data: any) => {
            if (data.message) {
                this.chatService.messages = Object.values(data.message);
            }
      });*/
      this.messageService.sendMessage(this.data.messages, message, contact, 'chat');
    }
}

