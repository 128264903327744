import * as fromSettings from './settings.actions';
import { Settings } from './settings.model';

export interface SettingsState {
      settings: Settings;
}

const estadoInicial: SettingsState = {
      settings: null
}

export function settingsReducer(state = estadoInicial, action: fromSettings.acciones): SettingsState {
      switch (action.type) {
            case fromSettings.SETTINGS:
                  return {
                        settings: { ... action.settings }
                  };
            case fromSettings.LOGOUT_SETTINGS:
                  return {
                        settings: null
                  };
            default:
                  return state;
      }
}
