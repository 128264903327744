import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { UsersState } from './users.reducers';
import { UsersService } from './users.service';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { Users } from './users.model';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styles: []
})


export class UsersComponent implements OnInit, OnDestroy {

  usuarios: any;
  data: any;
  resultsLength: number;
  displayedColumns: string[] = ['id', 'name', 'last_name', 'email', 'status', 'created', 'modified'];
  subscription: Subscription = new Subscription();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(private store: Store<UsersState>,
    private usersService: UsersService) {
    this.usersService.listUsers();
  }

  applyFilter(filterValue: string) {
    this.usuarios.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit() {
    this.subscription = this.store.select('users')
    .subscribe((data: any) => {

      if (data.users != null) {
        this.usuarios = new MatTableDataSource<Users>(Object.values(data.users));
        this.usuarios.sort = this.sort;
        this.usuarios.paginator = this.paginator;
        this.resultsLength = data.users.length;
      }

    });

    this.data = {
      title: 'Listado de Usuarios',
      subTitle: 'Listado de todos los usuarios creados en la plataforma.'
    };
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /*listUsers() {
    console.log('init Users');
    this.usersService.listUsers();

    this.store.select('users').subscribe(data => {
      
      if (data.users != null) {
        this.usuarios = data['users'];
      }
    });*/



  



}
