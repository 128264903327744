import * as fromActions from './contacts.actions'
import {Contact} from "./contact.model";

export interface ContactsState {
  contact: Contact
}

const initialStatus: ContactsState = {
  contact: null
};

export function contactsReducer(state = initialStatus, action: fromActions.actions): ContactsState {
  switch (action.type) {
    case fromActions.LIST_CONTACTS:
      return {
        contact: {...action.contact}
      };
    default:
      return state;
  }
}
