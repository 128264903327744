import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Contact} from "../contacts/contact.model";
import {Message} from "./message.model";
import {PlyrComponent} from "ngx-plyr";
import {IDatasource} from "ngx-ui-scroll";
import {MessageService} from "./message.service";
import {NewChatDialogComponent} from "../new-chat-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ContactService} from '../contacts/contact.service';
import {isChildNodeOf} from 'codelyzer/util/isChildNodeOf';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

  @Input() contact: Contact;
  @Input() messages: Message[];
  @Input() height: number;

  @ViewChild(PlyrComponent, { read: true, static: false })
  plyr: PlyrComponent;

  audioOptions: any = {
    autoplay: false,
    settings: [],
    controls: ['play', 'progress', 'current-time']
  };

  constructor(private messageService: MessageService, private contactService:   ContactService) { }

  ngOnInit() {
  }

  sendMessage (messages: Message[], message: HTMLTextAreaElement, contact: Contact) {
      this.messageService.sendMessage(messages, message.value, contact, 'chat');

/*
      this.contactService.getContacts();
*/

      contact.message['body'] = message.value;

      message.value = '';
  }
}
