import { Action } from "@ngrx/store";
import { Settings } from './settings.model';

export const SETTINGS = '[Settings] Variables de configuración';
export const LOGOUT_SETTINGS = '[Settings] LogOut';

export class SettingsAction implements Action {
      readonly type = SETTINGS;
      constructor( public settings: Settings ) {}
}

export class OutSettingsAction implements Action {
      readonly type = LOGOUT_SETTINGS;
}

export type acciones = SettingsAction | OutSettingsAction;