import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ChatService} from '../../../chat.service';

@Component({
  selector: 'app-add-tag-dialog',
  templateUrl: './add-tag-dialog.component.html',
  styleUrls: ['./add-tag-dialog.component.css']
})
export class AddTagDialogComponent implements OnInit {
  public tags = [];

  constructor(
    public addTagDialogRef: MatDialogRef<AddTagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private  chatService: ChatService
  ) { }

  ngOnInit() {
    this.tags = this.data.tags.result;
  }

  setTag(tag: []) {
    const url = 'https://zw.viajesinteractiva.com/admin/api/contacts';

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Authorization': localStorage.getItem('token'),
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    });

    this.http.post(url,
      {tag_id: tag['id'], chat_id: this.data.contact.id},
      {headers: httpHeaders, observe: 'response'}
    ).subscribe();

    this.data.contact.whts_tag = [];
    this.data.contact.whts_tag['color'] = tag['color'];
    this.data.contact.whts_tag['name'] = tag['name'];
    this.data.contact.whts_tag['id'] = tag['id'];

    this.addTagDialogRef.close();
  }
}
