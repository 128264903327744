import * as fromSearch from './search.accions';
import { Search } from './search.model';
import { map } from 'rxjs/operators';

export interface SearchState {
      search: Search;
}

const estadoInicial: SearchState = {
      search: null
}

export function searchReducer(state = estadoInicial, action: fromSearch.acciones): SearchState {
      switch (action.type) {
            case fromSearch.SEARCH:
                  return {
                        search: { ...action.search }
                  };
            default:
                  return state;
      }
}
