import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {ConfirmUploadDialogComponent} from './confirm-upload-dialog/confirm-upload-dialog.component';
import {Contact} from '../../contacts/contact.model';
import {Message} from '../message.model';

@Component({
    selector: 'app-attachment',
    templateUrl: './attachment.component.html',
    styleUrls: ['./attachment.component.css']
})
export class AttachmentComponent implements OnInit {

    @Input() contact: Contact;
    @Input() messages: Message[];

    constructor(public dialog: MatDialog) {
    }

    ngOnInit() {
    }

    openDialog(event: Event): void {
        const dialogRef = this.dialog.open(ConfirmUploadDialogComponent, {
            height: '300px',
            width: '500px',
            data: {
                event: event,
                contact: this.contact,
                messages: this.messages
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }
}
