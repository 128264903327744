import { Routes } from '@angular/router';
import { ReportesComponent } from '../reportes/reportes.component';
import { IngresoComponent } from '../ingreso/ingreso.component';
import { DetalleComponent } from '../detalle/detalle.component';
import { UsersComponent } from '../users/users.component';
import { UserDetailComponent } from '../users/user-detail/user-detail.component';
import { QuotesComponent } from '../quotes/quotes.component';
import { QuotesDetailComponent } from '../quotes/quotes-detail/quotes-detail.component';
import { QuotesManualComponent } from '../quotes/quotes-manual/quotes-manual.component';
import { QuotesFilterComponent } from '../quotes/quotes-filter/quotes-filter.component';
import { ChatComponent } from '../wht/chat/chat.component';

export const dashboardRoutes: Routes = [
      { path: '', component: ReportesComponent },
      { path: 'ingreso', component: IngresoComponent },
      { path: 'detalle', component: DetalleComponent },
      { path: 'usuarios', component: UsersComponent },
      { path: 'usuarios/add', component: UserDetailComponent },
      { path: 'usuarios/edit/:id', component: UserDetailComponent },
      { path: 'cotizaciones', component: QuotesComponent },
      { path: 'cotizaciones/add', component: QuotesDetailComponent },
      { path: 'cotizaciones/edit/:id', component: QuotesManualComponent },
      { path: 'cotizaciones/manual', component: QuotesManualComponent },
      { path: 'cotizaciones/asesor/:id', component: QuotesFilterComponent },
      { path: 'whatsapp/chat', component: ChatComponent },
];
