import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../api.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styles: []
})
export class NavbarComponent implements OnInit, OnDestroy {

  nameUser: any;
  cargando: Subscription = new Subscription();

  constructor(private apiService: ApiService, private store: Store<AppState>) {
  }

  ngOnInit() {
    this.cargando = this.store.select('auth')
      .pipe(filter(usuario => usuario.user != null))
    .subscribe(usuario => {
        this.nameUser = usuario.user['name'] + ' ' + usuario.user['last_name'];
    });
  }

  ngOnDestroy() {
    this.cargando.unsubscribe();
  }

  closeSidebar(){

    $(".sidebar").toggleClass("toggled");
    $(".navbar-menu-wrapper").toggleClass("w-100");
    $(".sidebar").toggleClass("displayNone");
    $(".main-panel").toggleClass("w-100");
    $("#close-sidebar .fas").toggleClass("fa-list fa-times");

  }

}
