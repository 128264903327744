import {Contact} from "./contact.model";

export const LIST_CONTACTS = '[Contacts]';

export class ListContactsAction {
  readonly type = LIST_CONTACTS;
  constructor(public contact: Contact) {}
}

export type actions = ListContactsAction;
