import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {HttpClient} from '@angular/common/http';
import {MessageService} from '../../message.service';

@Component({
    selector: 'app-confirm-upload-dialog',
    templateUrl: './confirm-upload-dialog.component.html',
    styleUrls: ['./confirm-upload-dialog.component.css']
})
export class ConfirmUploadDialogComponent implements OnInit {

    constructor(
        public confirmUploadDialogRef: MatDialogRef<ConfirmUploadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private http: HttpClient,
        private messageService: MessageService
    ) {
      this.files = Array.from(this.data.event.path[0].files);
    }

    public files: any;

    ngOnInit() {
    }

    uploadFiles() {
        this.data.event.path[0].value = '';
        for (let i = 0; i < this.files.length; i++) {
            this.messageService.sendMessage(this.data.messages, null, this.data.contact, 'file', this.files[i]);
        }
    }

    onNoClick(): void {
        this.data.event.path[0].value = '';
        this.confirmUploadDialogRef.close();
    }
}
