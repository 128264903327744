import {Component, Input, OnInit} from '@angular/core';
import {MessageService} from '../../messages/message.service';
import {Subject} from 'rxjs';
import {Contact} from '../contact.model';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {
    results: any;
    searchTerm = new Subject<string>();
    current: any;
    unfilteredList: Contact[] = [];

    @Input() contacts: Contact[];

    constructor(public messageService: MessageService) {
        this.messageService.search(this.searchTerm).subscribe(response => {
            this.contacts.splice(0, this.contacts.length);

            for (let i = 0; i < response['result'].length; i++) {
                this.contacts.push(response['result'][i]);
            }
        });
    }

    search(q: string) {
        if (q.length < 1) {
            this.clear();
            return;
        }

        if (this.unfilteredList.length < 1) {
            this.unfilteredList.push(...this.contacts);
        }

        this.current = q;

        this.searchTerm.next(q);
    }

    clear() {
        this.current = '';

        if (this.unfilteredList.length > 1) {
            this.contacts.splice(0, this.contacts.length);
            this.contacts.push(...this.unfilteredList);
            this.unfilteredList = [];
        }
    }

    ngOnInit() {
    }

}
