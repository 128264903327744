import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-filter-by-user-dialog',
  templateUrl: './filter-by-user-dialog.component.html',
  styleUrls: ['./filter-by-user-dialog.component.css']
})
export class FilterByUserDialogComponent implements OnInit {

  public users = [];

  constructor(public filterByUserDialogRef: MatDialogRef<FilterByUserDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private http: HttpClient) { }

  ngOnInit() {
    this.users = this.data.users.result;
  }

  filterByUser($userId) {
    localStorage.setItem('is_my_chats_filter_active', 'true');
    localStorage.setItem('filter_user_id', $userId);

    const url = 'https://zw.viajesinteractiva.com/admin/api/messages?u=' + $userId;

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Authorization': localStorage.getItem('token'),
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    });

    this.http.get(url, {headers: httpHeaders}).subscribe(response => {
      this.data.contacts.splice(0, this.data.contacts.length);

      for (let i = 0; i < response['result'].length; i++) {
        this.data.contacts.push(response['result'][i]);
      }
    });

    this.filterByUserDialogRef.close();
  }
}
