import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ApiService } from '../../api.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { NotifierService } from 'angular-notifier';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';

//import { Store, select } from '@ngrx/store';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})

export class LoginComponent implements OnInit {

  logo: string;
  bg: string = '';
  position: number;
  error: string;
  formGroup: FormGroup;

  private readonly notifier: NotifierService;

  constructor(private authService: AuthService, private apiService: ApiService,
    private loadingBar: LoadingBarService, notifierService: NotifierService, private router:Router) {

    this.logo = this.apiService.getLogo();

    this.notifier = notifierService;

    this.apiService.getBackground().subscribe(data => { 
      this.position = Math.floor(Math.random() * (data.hits.length - 1));
      this.bg = data.hits[this.position].largeImageURL;
    }, error => {
      this.bg = "";
    });

    if (localStorage.getItem('token')) {
      this.router.navigate(['/']);
    }


  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required
      ])
    });

  }

  getUrl() {
    return "url('" + this.bg + "')"; //Url del fondo
  }


  onSubmit(data: any) {
    //console.log(data);
    this.authService.getLogin(data);
  }

}
